import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import {url} from './config'

export const url = 'https://staging.chaavie.com:5050/';
// export const url = 'https://uat.chaavie.com:5051/';
// export const url = 'http://localhost:7001/';

// const token =
//   'pk.eyJ1IjoiY2hhYXZpZTRkZXYiLCJhIjoiY2t6MmJmZmRrMW1qbzJxbXZraHk2NXJydCJ9.nRXpeIXZ_aFfCKqtvnRyIw'; // dev token
const token =
  'pk.eyJ1IjoiY2hhYXZpZTRzaXQiLCJhIjoiY2t6MmN1cno3MGJ1aDJ3bjJkNHhrbmx5NCJ9.PHkt2ruIIz6liCxwiP3t5A'; // SIT token
// const token =
//   'pk.eyJ1IjoiY2hhYXZpZTR1YXQiLCJhIjoiY2t3b3hnZHVoMDVsejJvcWhvd3FqazdhdiJ9.JhZaa6xbXHJJfB6D4rAhvQ'; //uat
// const token =
//   'pk.eyJ1IjoiY2hhYXZpZTR1YXQiLCJhIjoiY2t3b3hnZHVoMDVsejJvcWhvd3FqazdhdiJ9.JhZaa6xbXHJJfB6D4rAhvQ';
@Injectable({
  providedIn: 'root',
})
export class LogicService {
  constructor(private http: HttpClient) {}

  // search_location(locate) {
  //   console.log(locate)
  //   return this.http
  //     .get(
  // https://api.mapbox.com/directions/v5/mapbox/driving-traffic/77.469453%2C8.704938%3B77.70806%2C8.72889.json?geometries=polyline&alternatives=true&steps=true&overview=full&access_token=pk.eyJ1IjoiZXhhbXBsZXMiLCJhIjoiY2p0MG01MXRqMW45cjQzb2R6b2ptc3J4MSJ9.zA2W0IkI0c6KaAhJfk9bWg
  //       `https://api.mapbox.com/geocoding/v5/mapbox.places/${locate}.json?country=in&limit=5&access_token=pk.eyJ1Ijoia2FudGhpbWF0aGkiLCJhIjoiY2t3ZGJ4azhkMXEwNjJwcDJ4cHkxMnhreiJ9.VysiEvR2Lu56J-jDqcsIiw`,
  //     )
  //     .pipe(map((res) => res))
  // }

  async search_location(locate): Promise<any> {
    return await this.http
      .get(
        //`https://api.mapbox.com/geocoding/v5/mapbox.places/tirunel.json?country=in&types=place%2Cdistrict%2Clocality%2Cneighborhood&access_token=pk.eyJ1Ijoia2FudGhpbWF0aGkiLCJhIjoiY2t3ZGJ4azhkMXEwNjJwcDJ4cHkxMnhreiJ9.VysiEvR2Lu56J-jDqcsIiw`,
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${locate}.json?country=in&limit=5&access_token=${token}`
      )
      .toPromise();
  }

  async get_route(start, end): Promise<any> {
    return await this.http
      .get(
        //`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/$;-84.512023,39.102779?geometries=geojson&access_token=YOUR_MAPBOX_ACCESS_TOKEN`,
        `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${start};${end}?geometries=polyline&alternatives=true&steps=true&overview=full&access_token=${token}`
      )
      .toPromise();
  }
  // https://api.mapbox.com/directions-matrix/v1/mapbox/driving/A;B;C;D;E?access_token=

  async get_distance(location): Promise<any> {
    return await this.http
      .get(
        //`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/$;-84.512023,39.102779?geometries=geojson&access_token=YOUR_MAPBOX_ACCESS_TOKEN`,
        `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${location}?geometries=polyline&alternatives=true&steps=true&overview=full&access_token=${token}`
      )
      .toPromise();
  }

  async check_availability(data): Promise<any> {
    return await this.http
      .post(
        //`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/$;-84.512023,39.102779?geometries=geojson&access_token=YOUR_MAPBOX_ACCESS_TOKEN`,
        `${url}trainApi/check_availability`,
        data
      )
      .toPromise();
  }

  async paying(data): Promise<any> {
    return await this.http
      .post(
        //`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/$;-84.512023,39.102779?geometries=geojson&access_token=YOUR_MAPBOX_ACCESS_TOKEN`,
        `${url}trainApi/paying`,
        data
      )
      .toPromise();
  }

  async update_transaction(data): Promise<any> {
    return await this.http
      .put(
        //`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/$;-84.512023,39.102779?geometries=geojson&access_token=YOUR_MAPBOX_ACCESS_TOKEN`,
        `${url}trainApi/update_transaction`,
        data
      )
      .toPromise();
  }

  async update_address(data): Promise<any> {
    return await this.http
      .put(`${url}trainApi/update_address`, data)
      .toPromise();
  }

  async get_orders(data): Promise<any> {
    return await this.http
      .post(`${url}trainApi/get_order_list`, data)
      .toPromise();
  }

  async bill_upload(data: FormData): Promise<any> {
    return await this.http.post(`${url}trainApi/bill_upload`, data).toPromise();
  }

  async invoice_upload(data: FormData): Promise<any> {
    return await this.http
      .post(`${url}trainApi/invoice_upload`, data)
      .toPromise();
  }

  async get_order(id): Promise<any> {
    return await this.http.get(`${url}trainApi/get_order/${id}`).toPromise();
  }
  // async add_video(): Promise<any> {
  //   return await this.http.get(`${url}videoApi/add_video`).toPromise();
  // }
  async get_video(): Promise<any> {
    return await this.http.get(`${url}videoApi/get_video`).toPromise();
  }

  async downloadFile(url): Promise<any> {
    return this.http.get(url, { responseType: 'blob' as 'json' }).toPromise();
  }

  //   getImage(url: string) {
  //     return this.http
  //                .get(url, {responseType: ResponseContentType.Blob})
  //                .map((res) => {
  //                    return new Blob([res.blob()], {type: res.headers.get('Content-Type')});
  //                });
  // }
}
