import { Injectable,EventEmitter } from '@angular/core';
//import url from './config'
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from, Subject, of } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
const url = 'https://staging.chaavie.com:5050/';
// const url = 'https://uat.chaavie.com:5051/';
// const url = 'http://localhost:7001/';
@Injectable({
  providedIn: 'root',
})


export class UserService {
  private listSizeSubject: Subject<boolean>;
  private _listSize: Observable<boolean>;
  private loadersubject: Subject<boolean>;
  private _loader: Observable<boolean>;

  invokeopen_video = new EventEmitter();
  subsVar: Subscription;
  constructor(private http: HttpClient) {
    this.loadersubject = new Subject();
    this._loader = this.loadersubject.asObservable();
    this.listSizeSubject = new Subject();
    this._listSize = this.listSizeSubject.asObservable();
  }


  onvideo_click() {
    this.invokeopen_video.emit();
  }

  async register(data): Promise<any> {
    return await this.http.post(`${url}userApi/register`, data).toPromise();
  }

  async send_otp(data): Promise<any> {
    return await this.http.post(`${url}userApi/send_otp`, data).toPromise();
  }

  async login(data): Promise<any> {
    return await this.http.post(`${url}userApi/login`, data).toPromise();
  }

  async get_config(data): Promise<any> {
    return await this.http
      .post(`${url}userApi/get_config`, { content_key: data })
      .toPromise();
  }

  // async get_config(data): Promise<any> {
  //   return await this.http.get(`${url}userApi/get_config`, data).toPromise()
  // }

  async forgot_password(data): Promise<any> {
    return await this.http
      .post(`${url}userApi/forgot_password`, data)
      .toPromise();
  }

  async reset_password(data, token): Promise<any> {
    return await this.http
      .post(`${url}userApi/reset_password`, data, { headers: { token: token } })
      .toPromise();
  }

  get listSize() {
    return this._listSize;
  }

  async login_success() {
    this.listSizeSubject.next(true);
  }

  get loader() {
    return this._loader;
  }

  async load_trigger(flag) {
    this.loadersubject.next(flag);
  }
}
